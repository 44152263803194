import { isIOS } from 'mobile-device-detect';

export interface IAppMessageResponse<TResult = unknown> {
  status: 'success' | 'fail';
  result: TResult;
}

export const getAsyncReceiveMessage = <TResult>(
  postMessageMethod: VoidFunction,
  targetMessageType: string,
  timeout = 5000,
): Promise<{ type: string; data: IAppMessageResponse<TResult> }> => {
  return new Promise((resolve, reject) => {
    if (typeof window === 'undefined') {
      reject('window undefined');
    }

    if (typeof document === 'undefined') {
      reject('document undefined');
    }

    const global = isIOS ? window : document;

    const timer = setTimeout(() => {
      reject('Message timeout');
      clearTimeout(timer);
      global.removeEventListener('message', messageHandler);
    }, timeout);

    const messageHandler = (event: Event) => {
      try {
        const messageEvent = event as MessageEvent;
        const message = JSON.parse(messageEvent.data);

        if (messageEvent.isTrusted || message.type !== targetMessageType) {
          return;
        }

        clearTimeout(timer);
        global.removeEventListener('message', messageHandler);

        if (message.data.status !== 'success') {
          reject(message);
          return;
        }

        resolve(message);
        return;
      } catch (error) {
        clearTimeout(timer);
        return reject(error);
      }
    };

    global.addEventListener('message', messageHandler);
    postMessageMethod && postMessageMethod();
  });
};
