import { isAndroid } from 'mobile-device-detect';
import { useRouter } from 'next/router';
import { cloneElement, HTMLAttributes, MouseEvent, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { postActivityLog, useLessorDetail, useModifyLessorDetail } from '@zaritalk/api';
import {
  COLOR,
  COOKIE_KEY,
  KAKAO_SAFE_AREA,
  LESSOR_ROUTES,
  VACANCY_MAP_ZOOM_LEVEL,
  ZARITALK_COMMUNITY_NAV_TOOLTIP,
  ZARITALK_TOKEN,
} from '@zaritalk/constants';
import { useCommunityPopupEntryCheck, useConfirmPaymentDateCertification } from '@zaritalk/domain/contact';
import { MARKER_TYPE_MATCHED_ZOOM_LEVEL } from '@zaritalk/domain/vacancy/constants/map';
import { useNavigationState } from '@zaritalk/global-state';
import {
  useCookie,
  useLessorRefererCheckAndUpdate,
  useLocalStorage,
  useTouchAnimation,
  useUserDevice,
} from '@zaritalk/hooks/index';
import { styled } from '@zaritalk/panda-css/jsx';
import { checkNotiPermission, checkTargetAppVersion, movePageWithAppStack, requestHaptic } from '@zaritalk/protocol';
import { ActionType } from '@zaritalk/types';
import { Icon, If, Layout, LegacyTypography, MoreIcon } from '@zaritalk/ui-components/index';
import { getDeviceType, isApp, noop } from '@zaritalk/utils/index';

const IOS_INSPECTION_PK = 14777;

const NAV_PAGE_PATH_NAME = {
  HOST: '임대장부',
  MY_ROOM: '방내놓기',
  REAL_ESTATE_MAP: '시세',
  VACANCY: '주변매물',
  COMMUNITY: '커뮤니티',
} as const;

const NAV_PAGE_ROUTES = [
  {
    name: NAV_PAGE_PATH_NAME.HOST,
    path: LESSOR_ROUTES.HOST,
    targetPathnames: [LESSOR_ROUTES.HOST, LESSOR_ROUTES.HOST_SPARE],
    iconElement: <Icon.HouseIcon />,
  },
  {
    name: NAV_PAGE_PATH_NAME.REAL_ESTATE_MAP,
    path: LESSOR_ROUTES.REAL_ESTATE_MAP,
    targetPathnames: [LESSOR_ROUTES.REAL_ESTATE_MAP],
    iconElement: <Icon.NavRealEstateIcon />,
  },
  {
    name: NAV_PAGE_PATH_NAME.COMMUNITY,
    path: LESSOR_ROUTES.COMMUNITY,
    targetPathnames: [LESSOR_ROUTES.COMMUNITY],
    iconElement: <Icon.SpeechBubbleLeft />,
  },
  {
    name: NAV_PAGE_PATH_NAME.MY_ROOM,
    path: LESSOR_ROUTES.MY_ROOM,
    targetPathnames: [LESSOR_ROUTES.MY_ROOM],
    iconElement: <MoreIcon.VacancyIcon />,
  },
  {
    name: NAV_PAGE_PATH_NAME.VACANCY,
    path: LESSOR_ROUTES.VACANCY_MAIN,
    targetPathnames: [LESSOR_ROUTES.VACANCY_MAIN],
    iconElement: <Icon.NavSearchIcon />,
  },
];

export const LESSOR_NAVIGATION_ROUTE_ORDERS = NAV_PAGE_ROUTES.reduce(
  (acc: string[], route) => [...acc, ...route.targetPathnames],
  [],
);

export default function LessorBottomNav() {
  const { isShowBottomNavigation } = useNavigationState();
  const router = useRouter();

  const firstPath = router.pathname.split('/')[1];
  const [targetPath, setTargetPath] = useState<string>(router.pathname);
  const [isRouting, setIsRouting] = useState<boolean>(false);
  const { setIsSourcePageCommunity } = useCommunityPopupEntryCheck();

  const [token] = useCookie(ZARITALK_TOKEN, '');
  const [cookieValue] = useCookie(COOKIE_KEY.LAST_CLICKED_DATE_FROM_COMMUNITY_AUTHENTICATION_POPUP, '');
  const [showRoomCommunityNavTooltip, setShowRoomCommunityNavTooltip] = useLocalStorage<boolean | ''>(
    ZARITALK_COMMUNITY_NAV_TOOLTIP,
    '',
  );

  const { isFulfilled } = useConfirmPaymentDateCertification();

  const { isKakaoSafeArea } = useUserDevice();

  const { data: lessorInfo, isLoading: isLessorDetailLoading } = useLessorDetail();
  useLessorRefererCheckAndUpdate();
  const { mutateAsync: updateLessorInfo } = useModifyLessorDetail();

  const isIOSInspection = useMemo<boolean>(() => lessorInfo?.lessorPK === IOS_INSPECTION_PK ?? false, [lessorInfo]);

  const moveToPage = async (event: MouseEvent<HTMLDivElement>, path: string) => {
    if (isRouting) return;
    requestHaptic({ type: isAndroid ? 'soft' : 'rigid' });
    router.pathname === path ? window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) : await router.replace(path);

    if (firstPath === 'community' && showRoomCommunityNavTooltip === '') {
      setShowRoomCommunityNavTooltip(true);
    }

    if (path === LESSOR_ROUTES.VACANCY_MAIN) {
      window.localStorage.setItem(VACANCY_MAP_ZOOM_LEVEL, MARKER_TYPE_MATCHED_ZOOM_LEVEL.VACANCIES.toString());
      movePageWithAppStack(LESSOR_ROUTES.VACANCY_MAP);
    }

    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    postActivityLog({ actionType: ActionType.VISIT }).catch(noop);

    if (token !== '') {
      (async function () {
        await updateLessorInfo({ deviceInfo: getDeviceType() });
      })();
      isApp() && checkNotiPermission();
    }
  }, [token, updateLessorInfo]);

  useEffect(() => {
    const handleRouterChangeStart = (path: string) => {
      setIsRouting(true);
      setTargetPath(path.split('?')[0]);
    };
    const handleRouteChangeComplete = (path: string) => {
      setIsRouting(false);
      setTargetPath(path.split('?')[0]);
      postActivityLog({ actionType: ActionType.VISIT }).catch(noop);
    };

    const handleRouteChangeError = () => {
      setIsRouting(false);
    };

    router.events.on('routeChangeStart', handleRouterChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouterChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [router.events]);

  return (
    <NavContainer kakaoSafeArea={isKakaoSafeArea} isShowBottomNavigation={isShowBottomNavigation}>
      <NavItemContainer>
        {NAV_PAGE_ROUTES.map((route) => {
          const isSelected = (route.targetPathnames as string[]).includes(targetPath);

          return (
            <If key={route.path} condition={route.name !== '커뮤니티' || (!isLessorDetailLoading && !isIOSInspection)}>
              <TouchAnimationSection
                onClick={(event) => {
                  if (route.name === '임대장부' && checkTargetAppVersion('1.2.5')) {
                    if (parseInt(cookieValue) !== 7 && !isFulfilled) {
                      setIsSourcePageCommunity();
                      router.replace(`${LESSOR_ROUTES.HOST_SPARE_APP}?isAuthentication=true`);
                      return;
                    }
                  }
                  moveToPage(event, route.path);
                }}
              >
                <NavItem>
                  <NewBadgeWrapper>
                    {route.name === NAV_PAGE_PATH_NAME.MY_ROOM && <NewBadge />}
                    {cloneElement(route.iconElement, {
                      ...route.iconElement.props,
                      fill: `${isSelected ? COLOR.BLACK : COLOR.GRAY03}`,
                    })}
                  </NewBadgeWrapper>
                  <NavTxt selected={isSelected}>{route.name}</NavTxt>
                </NavItem>
              </TouchAnimationSection>
            </If>
          );
        })}
      </NavItemContainer>
    </NavContainer>
  );
}

function TouchAnimationSection({ children, ...restProps }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const { touchRef, isTouch } = useTouchAnimation<HTMLDivElement>();

  return (
    <NavAnimationWrapper>
      <NavAnimation ref={touchRef} isTouch={isTouch} {...restProps}>
        {children}
      </NavAnimation>
    </NavAnimationWrapper>
  );
}

const NewBadgeWrapper = styled('span', {
  base: {
    position: 'relative',
    height: '24px',
  },
});

const NavContainer = styled('nav', {
  base: {
    flexCol: 'CENTER',

    position: 'fixed',
    bottom: 0,
    left: 0,

    width: '100%',
    minHeight: '$BOTTOM_NAV_HEIGHT',

    backgroundColor: '$white',

    borderTop: '1px solid token(colors.$gray00)',

    safeAreaPaddingBottom: '0px',

    zIndex: 5000,
    transition: 'bottom 0.2s linear',
  },

  variants: {
    kakaoSafeArea: {
      true: {
        paddingBottom: KAKAO_SAFE_AREA,
      },
    },
    isShowBottomNavigation: {
      true: {
        bottom: 0,
      },
      false: {
        bottom: -120,
      },
    },
  },
});

const NavItemContainer = styled(Layout.RowEvenlyCenter, {
  base: {
    maxWidth: '$MAX_BROWSER_WIDTH',
  },
});

const NavItem = styled('button', {
  base: {
    flexCol: 'CENTER',
    flex: 1,

    padding: '2px 2px 0',

    width: '100%',

    background: 'transparent',
    border: 'none',
    outline: 'none',

    cursor: 'pointer',
  },
});

const NavAnimationWrapper = styled('div', {
  base: {
    padding: '6px',
    width: '100%',
    minHeight: '$BOTTOM_NAV_HEIGHT - 1px',
  },
});

const NavAnimation = styled(Layout.ColCenter, {
  base: {
    transition: 'all 0.1s linear',
    borderRadius: '16px',
    position: 'relative',
    cursor: 'pointer',
  },

  variants: {
    isTouch: {
      true: {
        backgroundColor: '$gray01',
        transform: 'scale(0.95)',
      },
    },
  },
});

const NavTxt = styled(LegacyTypography, {
  base: {
    fontSize: '11px',
    lineHeight: '127%',
    textAlign: 'center',
    color: '$gray05',
    marginTop: '4px',
  },

  variants: {
    selected: {
      true: {
        fontWeight: 'bold',
        color: '$black',
      },
    },
  },
});

const NewBadge = styled(Layout.ColCenter, {
  base: {
    position: 'absolute',
    left: '0px',
    top: '1px',
    width: '8px',
    height: '8px',

    fontSize: '10px',
    fontWeight: 'bold',
    textAlign: 'center',

    borderRadius: '25px',

    backgroundColor: '$red',
    color: '$white',
    zIndex: 10,
  },
});
