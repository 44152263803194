import { getWindow } from 'ssr-window';

import { NOTION_PRIVACY_OF_SERVICE, NOTION_TERMS_OF_SERVICE } from './serviceKey';

export const ZARITALK_SUPPORT_BOT = 'https://zaritalk.channel.io/support-bots/39117';
export const ZARITALK_MAIN_SUPPORT_BOT = 'https://zaritalk.channel.io/support-bots/36782';

export const ZARITALK_STATIC_URL = 'https://static.zaritalk.com';
export const ZARITALK_LEGACY_BROWSER_URL = 'https://legacy-browser.zaritalk.com';
export const ZARITALK_NOTION_URL = 'https://n.zaritalk.com';

export const NOTION_TERMS_OF_SERVICE_URL = `${ZARITALK_NOTION_URL}/${NOTION_TERMS_OF_SERVICE}`;
export const NOTION_PRIVACY_OF_SERVICE_URL = `${ZARITALK_NOTION_URL}/${NOTION_PRIVACY_OF_SERVICE}`;

export const SLACK_REPORT_LESSEE_PAYMENT_FAIL_URL =
  'https://hooks.slack.com/services/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'; // #자리페이_결제실패
export const SLACK_LEASE_REPORT = 'https://hooks.slack.com/services/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'; // #자리톡_임대차신고
export const SLACK_API_REPORT_DEV_URL =
  'https://hooks.slack.com/services/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'; // #자리톡_프론트_dev_api_리포팅
export const SLACK_API_REPORT_PROD_URL =
  'https://hooks.slack.com/services/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'; // #자리톡_프론트_api_리포팅
export const SLACK_GLOBAL_ERROR_REPORT_URL =
  'https://hooks.slack.com/services/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'; // #자리톡_프론트_전역에러_리포팅
export const SLACK_ERROR_REPORT_URL = 'https://hooks.slack.com/services/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'; // #자리톡_프론트_에러_리포팅
export const SLACK_INTERNAL_API_REPORT_URL =
  'https://hooks.slack.com/services/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'; // #자리톡_프론트_internal_api_에러_리포팅
export const SLACK_EXTERNAL_API_REPORT_URL =
  'https://hooks.slack.com/services/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'; // #자리톡_프론트_external_api_에러_리포팅
export const SLACK_SERVER_SIDE_REPORT_URL =
  'https://hooks.slack.com/services/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'; // #자리톡_프론트_서버_에러_리포팅
export const SLACK_API_REPORT_URL =
  process.env.API_ENV === 'production' ? SLACK_API_REPORT_PROD_URL : SLACK_API_REPORT_DEV_URL;

// API
export const ZARITALK_DEV_API = 'https://dev-api.zaritalk.com';
export const ZARITALK_STAGE_API = 'https://stage-api.zaritalk.com';
export const ZARITALK_PROD_API = 'https://api.zaritalk.com';

export const API_URL =
  process.env.API_ENV === 'localhost' || process.env.API_ENV === 'development'
    ? ZARITALK_DEV_API
    : process.env.API_ENV === 'stage'
    ? ZARITALK_STAGE_API
    : ZARITALK_PROD_API;

// 임대장부
export const ZARITALK_GUIDE_LINK = `${ZARITALK_NOTION_URL}/3b7845e1d8c9448fb6010018525aa895`;
export const ZARITALK_LOCAL_DOMAIN = 'zaritalk-front.org';
export const ZARITALK_DEV_URL = 'https://dev.zaritalk.com';
export const ZARITALK_STAGE_URL = 'https://stage.zaritalk.com';
export const ZARITALK_HOST_URL = 'https://zaritalk.com';

export const LESSOR_SITE_URL =
  process.env.API_ENV === 'localhost'
    ? getWindow().location.origin
    : process.env.API_ENV === 'development'
    ? ZARITALK_DEV_URL
    : process.env.API_ENV === 'stage'
    ? ZARITALK_STAGE_URL
    : ZARITALK_HOST_URL;

// Community
export const COMMUNITY_POST_IMAGE_BUCKET = 'https://community-img.zaritalk.com/';
export const COMMUNITY_STAGE_URL = 'https://stage-community.zaritalk.com';
export const COMMUNITY_HOST_URL = 'https://community.zaritalk.com';
export const COMMUNITY_SUPPORT_BOT = 'https://zaritalk.channel.io/support-bots/51359';
export const COMMUNITY_SITE_URL =
  process.env.API_ENV === 'localhost'
    ? getWindow().location.origin
    : process.env.API_ENV === 'production'
    ? COMMUNITY_HOST_URL
    : COMMUNITY_STAGE_URL;

// Realtor
export const REALTOR_STAGE_URL = 'https://stage-realtor.zaritalk.com';
export const REALTOR_DEV_URL = 'https://dev-realtor.zaritalk.com';
export const REALTOR_PROD_URL = 'https://realtor.zaritalk.com';

export const REALTOR_SITE_URL =
  process.env.API_ENV === 'localhost'
    ? getWindow().location.origin
    : process.env.API_ENV === 'development'
    ? REALTOR_DEV_URL
    : process.env.API_ENV === 'stage'
    ? REALTOR_STAGE_URL
    : REALTOR_PROD_URL;

export const GONGSIL_IMAGE_BUCKET = 'https://gongsiltalk-room-images.s3.ap-northeast-2.amazonaws.com/';

export const REALTOR_TALK_IMAGE_BUCKET = 'https://static.zaritalk.com/';
export const REALTOR_RISIZE_IMAGE_BUCKET = 'https://gongsil-img.zaritalk.com/';
export const REALTOR_SUPPORT_BOT = 'https://zaritalk.channel.io/support-bots/26717';

export const ZARITALK_APP_INSTALL_LINK = 'https://abr.ge/2wg2k5z';
export const ZARITALK_APP_INSTALL_BANNER_LINK = 'https://abr.ge/od3voj';

export const ZARITALK_PUBLIC_IMAGE_BUCKET = 'https://public.zaritalk.com';
export const ZARITALK_RESIZED_PUBLIC_IMAGE_BUCKET = 'https://public-img.zaritalk.com';

// Lessee
export const LESSEE_STAGE_URL = 'https://stage-tenant.zaritalk.com';
export const LESSEE_DEV_URL = 'https://dev-tenant.zaritalk.com';
export const LESSEE_PROD_URL = 'https://tenant.zaritalk.com';

export const LESSEE_SITE_URL =
  process.env.API_ENV === 'localhost'
    ? getWindow().location.origin
    : process.env.API_ENV === 'development'
    ? LESSEE_DEV_URL
    : process.env.API_ENV === 'stage'
    ? LESSEE_STAGE_URL
    : LESSEE_PROD_URL;

export const LESSEE_BILL_SUPPORT_BOT = 'https://zaritalk.channel.io/support-bots/35609';
export const LESSEE_TAX_REFUND_SUPPORT_BOT = 'https://tally.so/r/w8zVQY';
export const LESSEE_TAX_REFUND_INFORMATION_LINK = `${ZARITALK_NOTION_URL}/b9b4399932e742b8a111b2ff7dddb3c3`;
export const LESSEE_NAVER_DEPOSIT_INSURANCE_LINK = 'https://fin.land.naver.com/insurance/tenant';
export const LESSEE_DICTIONARY_LINK =
  'https://m.easylaw.go.kr/MOB/SearchEngineRetrieve.laf?srchType=detail&curPage=1&section=EZP_03&outmax=10&mb_input=%EC%9E%84%EB%8C%80%EC%B0%A8';
export const LESSEE_CONFIRM_SUPPORT_FUND_LINK =
  'https://www.bokjiro.go.kr/ssis-teu/twataa/wlfareInfo/moveTWAT52011M.do?wlfareInfoId=WLF00004661&wlfareInfoReldBztpCd=01';
export const LESSEE_HOUSING_SUPPORT_LINK = 'https://m.myhome.go.kr/hws/portal/dgn/selectSelfDiagnosisIntroView2.do';
export const LESSEE_SEARCH_MALICIOUS_LESSOR_LINK = 'https://www.khug.or.kr/jeonse/web/s01/s010321.jsp';
export const LESSEE_FAQ_LINK = 'https://docs.channel.io/zaritalk/ko';

export const IOS_STORE = 'https://apps.apple.com/kr/app/id1583506656';
export const ANDROID_STORE = 'https://play.google.com/store/apps/details?id=com.callbus.zaritalk&hl=ko';
export const APP_STORE = `itms-apps://apps.apple.com/app/id1583506656?action=write-review`;
export const PLAY_STORE = `market://details?id=com.callbus.zaritalk`;

// Static file url
export const FRONT_STATIC_URL = 'https://front-static.zaritalk.com';
export const EMOJI_STATIC_URL = `${FRONT_STATIC_URL}/emoji`;
export const IMAGE_STATIC_URL = `${FRONT_STATIC_URL}/image`;
export const SVG_STATIC_URL = `${FRONT_STATIC_URL}/svg`;

// Pay
export const ZARIPAY_SUPPORT_BOT = 'https://zaritalk.channel.io/support-bots/102258';
export const ZARITALK_EXCHANGE_NPAY_SUPPORT_BOT = 'https://zaritalk.channel.io/support-bots/103048';

export const ZARITALK_BRUNCH_URL = 'https://brunch.co.kr/@zaritalk';

export const ZARITALK_AWS_API_GATEWAY_URL = 'https://qj2z1ojqk0.execute-api.ap-northeast-2.amazonaws.com';
