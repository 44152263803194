import { useCallback, useEffect, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { getWindow } from 'ssr-window';

const localStorageStateAtom = atom<{ [key: string]: any }>({});

interface IUseLocalStorageStateResult<T> {
  storageValue: T;
  setValue: (value: T) => void;
  options: { isLoading: boolean };
}

export function useLocalStorageState<T>(key: string, initialValue: T): IUseLocalStorageStateResult<T> {
  const [storedValue, setStoredValue] = useAtom(localStorageStateAtom);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getLocalStorageValues = useCallback(() => {
    let localStorageValues = {};
    for (let i = 0; i < getWindow().localStorage.length; i++) {
      const key = getWindow().localStorage.key(i);
      if (key) {
        localStorageValues = { ...localStorageValues, [key]: getWindow().localStorage.getItem(key) };
      }
    }
    return localStorageValues;
  }, [key]);

  useEffect(() => {
    setIsLoading(true);
    setStoredValue(getLocalStorageValues());
    setIsLoading(false);
  }, [getLocalStorageValues]);

  const setValue = useCallback(
    (value: T) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue[key]) : value;
        getWindow().localStorage?.setItem(key, String(valueToStore));
        setStoredValue(getLocalStorageValues());
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  return {
    storageValue: storedValue[key] ?? initialValue,
    setValue,
    options: {
      isLoading,
    },
  };
}
