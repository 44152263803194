import { useLocalStorageState } from '@zaritalk/global-state';

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void, { isLoading: boolean }] {
  const {
    storageValue,
    setValue,
    options: { isLoading },
  } = useLocalStorageState(key, initialValue);

  return [storageValue, setValue, { isLoading }];
}
