import Router from 'next/router';
import { getWindow } from 'ssr-window';

import { ZARITALK_APP_INSTALL_LINK } from '@zaritalk/constants';
import { MESSAGE_RES_TYPE } from '@zaritalk/types';

import { checkTargetAppVersion, isZaritalkApp } from './appInfomation';
import { requestPopToTopScreen, requestPushScreen, requestPopScreen, ScreenType } from './appProtocol';
import { CHANGE_URL_AFTER_STACK_POP_UPDATE_VERSION, STACK_NAVIGATION_UPDATE_VERSION } from './constants';
import { getAsyncReceiveMessage } from './helper';

export const moveToAppStore = (installLink = ZARITALK_APP_INSTALL_LINK): void => {
  if (!isZaritalkApp()) {
    getWindow().open(installLink, '_blank');
  }
};

export const movePageWithAppStack = async (path: string, screenType: ScreenType = 'Detail') => {
  if (checkTargetAppVersion(STACK_NAVIGATION_UPDATE_VERSION)) {
    return await getAsyncReceiveMessage(
      () =>
        requestPushScreen({
          url: `${getWindow().location.origin}${path}`,
          screenType: checkTargetAppVersion('1.2.8') ? screenType : 'Detail',
        }),
      MESSAGE_RES_TYPE.PUSH_SCREEN,
    );
  } else {
    return await Router.push(path, path, { shallow: true });
  }
};

export const replacePageWithAppStack = async (path: string, screenType: ScreenType = 'Detail') => {
  if (checkTargetAppVersion(STACK_NAVIGATION_UPDATE_VERSION)) {
    return await getAsyncReceiveMessage(
      () =>
        requestPushScreen({
          url: `${getWindow().location.origin}${path}`,
          screenType: checkTargetAppVersion('1.2.8') ? screenType : 'Detail',
        }),
      MESSAGE_RES_TYPE.PUSH_SCREEN,
    );
  } else {
    return await Router.replace(path);
  }
};

export const backPageWithAppStack = (href?: string) => {
  if (checkTargetAppVersion(STACK_NAVIGATION_UPDATE_VERSION)) {
    href ? requestPopToTopScreen({ url: href }) : requestPopScreen();
    return;
  }

  if (href) {
    Router.replace(href);
    return;
  }

  Router.back();
};

export const changeUrlAfterStackPop = (path: string, replace = true) => {
  if (checkTargetAppVersion(CHANGE_URL_AFTER_STACK_POP_UPDATE_VERSION)) {
    requestPopScreen(path, replace);
    return;
  }

  if (replace && path) {
    Router.replace(path);
    return;
  }

  Router.back();
};
